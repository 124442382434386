import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CreateTaskWithSubtasks() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subtaskCount, setSubtaskCount] = useState(0);
  const [dueDate, setDueDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const asanaToken = localStorage.getItem('asanaToken');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
  
    if (!title || !description || !subtaskCount || !dueDate) {
      setMessage('Все поля должны быть заполнены.');
      setLoading(false);
      return;
    }
  
    try {
      // Получение информации о пользователе и рабочих пространствах
      const userResponse = await axios.get('https://app.asana.com/api/1.0/users/me', {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
  
      const workspaceId = userResponse.data.data.workspaces[0].gid; // Берём первое рабочее пространство пользователя
  
      // Создание задачи в My Tasks пользователя
      const taskResponse = await axios.post('https://app.asana.com/api/1.0/tasks', {
        data: {
          name: title,
          notes: description,
          due_on: dueDate,
          workspace: workspaceId, // Используем полученный workspaceId
          assignee: 'me',
        }
      }, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
  
      const taskId = taskResponse.data.data.gid; // Используем gid для идентификатора задачи
  
      // Создание подзадач
      for (let i = 0; i < parseInt(subtaskCount, 10); i++) {
        await axios.post(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks`, {
          data: {
            name: `${title}`,
            notes: description,
            due_on: dueDate,
          }
        }, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
      }
  
      setMessage('Задача с подзадачами успешно создана.');
    } catch (error) {
      console.error('Ошибка при создании задачи:', error);
      setMessage(`Ошибка: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Создание задачи с подзадачами</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Название</Form.Label>
          <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Описание</Form.Label>
          <Form.Control as="textarea" rows={3} value={description} onChange={e => setDescription(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Количество подзадач</Form.Label>
          <Form.Control type="number" value={subtaskCount} onChange={e => setSubtaskCount(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Дата выполнения</Form.Label>
          <Form.Control type="date" value={dueDate} onChange={e => setDueDate(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate(-1)} className="ms-2">Назад</Button>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </Container>
  );
}

export default CreateTaskWithSubtasks;
