import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './AuthPage';
import MainPage from './MainPage';
import MassiveRename from './Scripts/MassiveRename';
import CreateTaskWithSubtasks from './Scripts/CreateTaskWithSubtasks';
import AddCommentToOverdueTasks from './Scripts/AddCommentToOverdueTasks';
import CreateSubtasks from './Scripts/CreateSubtasks';
import DuplicateTask from './Scripts/DuplicateTask';
import Cutoff from './Scripts/Cutoff';
import MoveSubtasksToProjectSection from './Scripts/MoveSubtasksToProjectSection'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="*" element={<Navigate replace to="/auth" />} />
        <Route path="/massive-rename" element={<MassiveRename />} />
        <Route path="/create-task" element={<CreateTaskWithSubtasks />} />
        <Route path="/kicker" element={<AddCommentToOverdueTasks />} />
        <Route path="/subtasks" element={<CreateSubtasks/>} />
        <Route path="/duplicator" element={<DuplicateTask/>} />
        <Route path="/cutoff" element={<Cutoff/>} />
        <Route path="/movesubs" element={<MoveSubtasksToProjectSection/>} />
      </Routes>
    </Router>
  );
}

export default App;
