import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer';
import './App.css'; // Убедитесь, что CSS файл импортирован

const feedbackUrl = "https://form.asana.com/?k=2A791dGmddCK7ovhuw4ldA&d=656176460444"; // Замените URL на ваш


function AuthPage() {
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.get('https://app.asana.com/api/1.0/users/me', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      localStorage.setItem('asanaToken', token);
      localStorage.setItem('asanaUserName', response.data.data.name);
      navigate('/main');
    } catch (error) {
      alert('Ошибка авторизации. Проверьте ваш токен.');
    }
  };

  return (
    <Container className="pt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2 className="text-center mb-4">Авторизация Asana</h2>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicToken">
              <Form.Label>Asana API токен</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите ваш Asana API токен"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <Form.Text className="text-muted">
                Можно получить <a href="https://app.asana.com/0/my-apps" target="_blank" rel="noopener noreferrer">здесь</a>.
               <br></br>
                Важно! Сервис не хранит ваш токен, он используется вами только внутри сессии!
              </Form.Text>
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleLogin}>
                Войти
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      <button
        className="feedback-btn btn btn-primary"
        onClick={() => window.location.href = feedbackUrl}
      >
        Фидбек
      </button>
      <Footer />
    </Container>
  );
}

export default AuthPage;
