import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Navbar, Table, Nav } from 'react-bootstrap';
import Footer from './Footer';
import './App.css'; // Убедитесь, что CSS файл импортирован


function MainPage() {
  const feedbackUrl = "https://form.asana.com/?k=2A791dGmddCK7ovhuw4ldA&d=656176460444"; // Замените URL на ваш
  const navigate = useNavigate();
  const asanaUserName = localStorage.getItem('asanaUserName');

  React.useEffect(() => {
    if (!asanaUserName) {
      navigate('/auth');
    }
  }, [navigate, asanaUserName]);

  const handleLogout = () => {
    localStorage.removeItem('asanaToken');
    localStorage.removeItem('asanaUserName');
    navigate('/auth');
  };

  const executeScript = (scriptIndex) => {
    switch (scriptIndex) {
      case 0: // Массовое переименование
        navigate('/massive-rename');
        break;
      case 1: // Создание задачи с подзадачами
        navigate('/create-task');
        break;
      case 2: //Напиныватель
        navigate('/kicker');
        break;
      case 3: //Дубликатор в сабтаски
        navigate('/subtasks');
        break;
      case 4: //Дубликатор задач
        navigate('/duplicator');
        break;
      case 5: //Висяки
        navigate('/cutoff');
        break;
      case 6: //Сабтаски
        navigate('/movesubs');
        break;

      default:
        alert('Запуск скрипта: ' + scriptIndex);
        break;
    }
  };


  const scripts = [
    { name: "Массовое переименование", description: "Скрипт будет полезен, если необходимо быстро, в рамках одного проекта переименовать повторяющиеся элементы в названии и описании. Например: Название версии. Скрипт переименует все задачи и подзадачи в указанном проекте.", action: "Выполнить" },
    { name: "Создание задачи с подзадачами", description: "Скрипт будет полезен для быстрого распространения информации. Он создает корневую задачу в MyTasks и неограниченное количество подзадач с идентичным названием, описанием и DueDate.", action: "Выполнить" },
    { name: "👞 Напиныватель", description: "Скрипт проходится по задачам с просроченным DueDate и оставляет ваш комментарий", action: "Выполнить" },
    { name: "Дубликатор в сабтаски", description: "Скрипт берет название, описание, DueDate указанной задачи и создает дубликаты в сабтасках. Может быть полезен для распространения информации, когда задача уже готова", action: "Выполнить" },
    { name: "Дубликатор задач", description: "Скрипт с гибкой настройкой. Позволяет продублировать задачу N количество раз, ее подзадачи, DueDate, фолловеров, назначения, описание, кастомные поля, теги. Скрипт автоматически добавит задачу в тот же проект, где находилась изначальная", action: "Выполнить" },
    { name: "☠️ Поиск зависших задач", description: "Скрипт находит 'Зависшие' задачи в указанном проекте, в которых не было никакой активности за указанное количество времени. ", action: "Выполнить" },
    { name: "Добавление сабтасок в проект", description: "Скрипт будет полезен если необходмо добавить подзадачи в проект, при этом не убирая их из корневой ", action: "Выполнить" },

  ];

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>Привет, {asanaUserName}!</Navbar.Brand>
          <Nav className="ml-auto">
            <Button variant="danger" onClick={handleLogout}>Выход</Button>
          </Nav>
        </Container>
      </Navbar>
      <Container className="pt-5">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название</th>
              <th style={{ width: '60%' }}>Описание</th>
              <th style={{ width: '20%' }}>Действия</th>
            </tr>
          </thead>
          <tbody>
            {scripts.map((script, index) => (
              <tr key={index}>
                <td>{script.name}</td>
                <td>{script.description}</td>
                <td>
                  <Button variant="primary" style={{ width: '100%' }} onClick={() => executeScript(index)}>Выполнить</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <button
          className="feedback-btn btn btn-primary"
          onClick={() => window.location.href = feedbackUrl}
        >
          Фидбек
        </button>
        <Footer />
      </Container>
    </>
  );
}

export default MainPage;
