import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function TasksWithoutActivity() {
  const [projectIdOrUrl, setProjectIdOrUrl] = useState('');
  const [days, setDays] = useState('');
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const asanaToken = localStorage.getItem('asanaToken'); // Убедитесь, что asanaToken действителен

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    setTasks([]);

    const projectId = projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - days);

    try {
      const response = await axios.get(`https://app.asana.com/api/1.0/projects/${projectId}/tasks`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
        params: { opt_fields: 'name,modified_at,permalink_url,completed' }
      });

      const openTasks = response.data.data.filter(task => !task.completed);

      const filteredTasks = openTasks.filter(task => {
        const taskModifiedDate = new Date(task.modified_at);
        return taskModifiedDate <= cutoffDate;
      });

      if (filteredTasks.length > 0) {
        setTasks(filteredTasks);
        setMessage(`Найдено ${filteredTasks.length} задач без активности за указанный период.`);
      } else {
        setMessage('Нет задач без активности за указанный период.');
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      setMessage(`Ошибка: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Поиск задач без активности</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>ID или ссылка на проект</Form.Label>
          <Form.Control type="text" value={projectIdOrUrl} onChange={e => setProjectIdOrUrl(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Количество дней</Form.Label>
          <Form.Control type="number" value={days} onChange={e => setDays(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate(-1)} className="ms-2">Назад</Button>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
      <ListGroup className="mt-3">
        {tasks.map(task => (
          <ListGroup.Item key={task.gid}>
            <a href={task.permalink_url} target="_blank" rel="noopener noreferrer">{task.name}</a>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
}

export default TasksWithoutActivity;
