import React, { useState } from 'react';
import { Button, Container, Form, Alert, Spinner} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CreateSubtasks() {
  const [taskUrlOrId, setTaskUrlOrId] = useState('');
  const [subtaskCount, setSubtaskCount] = useState(0);
  const [copyDueDate, setCopyDueDate] = useState(false); // Новое состояние для чекбокса
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const asanaToken = localStorage.getItem('asanaToken');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    // Извлечение ID задачи из URL или использование напрямую введенного ID
    const taskIdMatch = taskUrlOrId.match(/\/(\d+)(?:\/f)?$/);
    const taskId = taskIdMatch ? taskIdMatch[1] : null;

    if (!taskId) {
      setMessage('Пожалуйста, убедитесь, что ссылка на задачу или ID задачи введены корректно.');
      setLoading(false);
      return;
    }

    try {
      // Получение информации о задаче
      const taskResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      
      const { name, notes, due_on } = taskResponse.data.data;

      // Создание подзадач
      for (let i = 0; i < subtaskCount; i++) {
        await axios.post(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks`, {
          data: {
            name,
            notes,
            due_on: copyDueDate ? due_on : undefined, // Условное копирование DueDate
          }
        }, {
          headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
      }

      setMessage('Подзадачи успешно созданы.');
    } catch (error) {
      console.error('Ошибка при создании подзадач:', error);
      setMessage(`Ошибка: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Создание подзадач</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка на задачу или ID</Form.Label>
          <Form.Control type="text" value={taskUrlOrId} onChange={e => setTaskUrlOrId(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Количество подзадач</Form.Label>
          <Form.Control type="number" value={subtaskCount} onChange={e => setSubtaskCount(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check 
            type="checkbox" 
            label="Копировать DueDate?" 
            checked={copyDueDate} 
            onChange={e => setCopyDueDate(e.target.checked)} 
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Выполнить'}
        </Button>
        <Button variant="secondary" onClick={() => navigate(-1)} className="ms-2">Назад</Button>

      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </Container>
  );
}

export default CreateSubtasks;
