import React, { useState } from 'react';
import { Button, Container, Form, Alert} from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function MoveSubtasksToProjectSection() {
  const [taskUrlOrId, setTaskUrlOrId] = useState('');
  const [projectIdOrUrl, setProjectIdOrUrl] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const asanaToken = localStorage.getItem('asanaToken');

  const loadSections = async () => {
    setLoading(true);
    const projectId = projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl;
    try {
      const response = await axios.get(`https://app.asana.com/api/1.0/projects/${projectId}/sections`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
      });
      setSections(response.data.data);
      setLoading(false);
    } catch (error) {
      setMessage(`Ошибка при загрузке секций: ${error.toString()}`);
      setLoading(false);
    }
  };

  const moveSubtasks = async () => {
    setLoading(true);
    setMessage('');
    const taskId = taskUrlOrId.includes('/0/') ? taskUrlOrId.match(/\/(\d+)(?:\/f)?$/)?.[1] : taskUrlOrId;

    try {
      const subtasksResponse = await axios.get(`https://app.asana.com/api/1.0/tasks/${taskId}/subtasks`, {
        headers: { 'Authorization': `Bearer ${asanaToken}` },
        params: { opt_fields: 'gid' }
      });

      const subtasks = subtasksResponse.data.data;

      for (const subtask of subtasks) {
        // Добавление подзадачи непосредственно в проект и указанную секцию
        await axios.post(`https://app.asana.com/api/1.0/tasks/${subtask.gid}/addProject`, {
            data: {
                project: projectIdOrUrl.match(/(\d+)$/)?.[0] || projectIdOrUrl,
                section: selectedSection
            }
        }, {
            headers: { 'Authorization': `Bearer ${asanaToken}` },
        });
    }
    
      setMessage(`Все задачи успешно перенесены в секцию.`);
    } catch (error) {
      setMessage(`Ошибка при переносе задач: ${error.response?.data?.errors?.[0]?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Добавление подзадач в проект</h2>
      {message && <Alert variant="info">{message}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID корневой задачи</Form.Label>
          <Form.Control type="text" value={taskUrlOrId} onChange={e => setTaskUrlOrId(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ссылка или ID проекта в который необходимо осуществить перенос</Form.Label>
          <Form.Control type="text" value={projectIdOrUrl} onChange={e => setProjectIdOrUrl(e.target.value)} />
        </Form.Group>
        <Button variant="secondary" onClick={loadSections} disabled={loading || !projectIdOrUrl}>
          Загрузить секции
        </Button>
        {sections.length > 0 && (
          <Form.Group className="mt-3">
            <Form.Label>Выберите секцию</Form.Label>
            <Form.Select onChange={e => setSelectedSection(e.target.value)}>
              <option value="">Выберите секцию</option>
              {sections.map(section => (
                <option key={section.gid} value={section.gid}>{section.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
        <br></br>
        <br></br>
        <Button variant="primary" onClick={moveSubtasks} disabled={loading || !selectedSection}>
          Выполнить
        </Button> 
        <Button variant="secondary" onClick={() => navigate(-1)} className="ms-2">Назад</Button>

      </Form>
    </Container>
  );
}

export default MoveSubtasksToProjectSection;
